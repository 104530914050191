@import '../../global/styles/variables';

button.next {
    width: 25px;
    height: 40px;
    background: none;
    border:none;
    padding:0;
    margin:0;
    outline:none;
    cursor: pointer;
    transition-duration: .3s;
    transition-property: all;
    img {
        display:block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        cursor: pointer;
        width: 100%;
    }
    &:active {
        img {
            animation-name: bouncing;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
            animation-fill-mode: both;
            animation-direction: normal;
            animation-duration: 1s;
        }
    }
    &.hide {
        opacity: 0;
        pointer-events: none;
        cursor:none;
    }
}

@keyframes bouncing {
    0% {
        top: 0;
    }
    50% {
        top: 3rem;
    }
    100% {
        top: 0;
    }
}

@media (max-width: $mobileBreak) {
    button.next {
        /* display:none; */
        width: 15px;
        height: 24px;
    }
}
