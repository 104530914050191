@import '../../global/styles/mixins';
@import '../../global/styles/variables';

.news {
    height: 100vh;
    .news-inner {
        padding-top: 155px;
        height: calc(100vh - 155px);
        &>h1 {
            margin-top:0;
            margin-bottom:10px;
        }
        .news-inner-scrollable {
            height: calc(100vh - 211px);
            position: relative;
            width: calc(100vw - 81px);
            margin-left:-81px;
            padding-left:81px;
            margin-right: -81px;
            padding-right: 81px;
            overflow: hidden;
            .news-inner-scrollable-content { 
                height:auto;
                position: absolute;
                &>p {
                    max-width: 1440px;
                    padding-left: 81px;
                    padding-right: 162px;
                    margin-left: -81px;
                    margin-bottom: 0;
                    margin-top:0;
                }
            }
        }
    }
    .carousel-navigator {
        padding-top:30px;
        display:flex;
        button {
            margin-right: 20px;
        }
    }
    .carousel {
        margin-top:20px;
        height: 560px;
        .inner {
            white-space: nowrap;
            transition-property: all;
            transition-duration: .4s;
            transition-timing-function: cubic-bezier(0.65,0.05,0.36,1);;
            position: absolute;
            display:flex;
            flex-direction: row;
            flex-wrap: nowrap;
            left:0;
            .card {
                float:left;
                display:block;
                margin-bottom: 0;
                height:515px;
                width:360px;
                padding-right:34px;
                margin-left:0;
                margin-right:0;
            }
        }
    }
    .next-container {
        display:flex;
        flex-direction: row;
        justify-content: center;
        height:100px;
        padding-bottom: 50px;
        margin-bottom: 80px;
    }

    &.active {
        h1 {
            animation-delay: .4s;
            animation-duration: .3s;
            animation-name: fadeInUp;
        }
        p {
            animation-delay: .6s;
            animation-duration: .3s;
            animation-name: fadeInUp;
        }
        .news-inner-scrollable {
            .carousel-navigator {
                animation-delay: .8s;
                animation-duration: .3s;
                animation-name: fadeInUp;
            }
            .carousel {
                .inner {
                    .card {
                        animation-delay: calc(1s + .2s * var(--index));
                        animation-duration: .2s;
                        animation-name: fadeInRight;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobileBreak) {
    .news {
        &>.containers {
            justify-content: flex-start;
        }
        .news-inner {
            padding-top: 100px;
            height: calc(100vh - 100px);
            &>h1 {
                font-size: pt2Rem(40);
                line-height: pt2Rem(50);
            }
            .news-inner-scrollable {
                height: calc(100vh - 241px);
                margin-right:0;
                display:flex;
                flex-direction: column;
                .news-inner-scrollable-content {
                    position: relative;
                    display:flex;
                    flex-direction: column;
                    height:auto;
                    transition-duration: .2s;
                    width: 100%;
                    &>p {
                        padding:0;
                        margin:0;
                        font-size: pt2Rem(16);
                        line-height: pt2Rem(24);
                        order:-2;
                    }
                    .carousel {
                        height: 480px;
                        margin-left:0;
                        .inner {
                            transition-duration: .2s;
                            .card {
                                width: 330px;
                                margin:0;
                                height: 450px;
                                padding-right:14px;
                                .body {
                                    .image {
                                        margin-bottom: 30px;
                                    }
                                    h4 {
                                        margin-bottom: 15px;
                                    }
                                    p.sub {
                                        font-size: pt2Rem(16) !important;
                                        line-height: pt2Rem(24) !important;
                                    }
                                }
                            }
                            
                            &.swiping {
                                transition-property: none;
                            }
                        }
                    }
                }
            }
        }
    }
}