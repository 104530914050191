@import '../../global/styles/variables';
@import '../../global/styles/mixins';

.menu {
    position: absolute;
    right:60px;
    margin-right: 81px;
    transition-duration: .3s;
    transition-property: all;
    .menu-icon {
        transition-duration: .3s;
        transition-property: all;
        position: absolute;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-color: #000;
        display:flex;
        align-items: center;
        z-index: 2;
        cursor: pointer;
        p {
            transition-duration: .3s;
            transition-property: all;
            width: inherit;
            text-align: center;
            font-size: pt2Rem(24);
            line-height: pt2Rem(35);
            letter-spacing: pt2Rem(1);
            color: $colorFG2;
        }
        p.closed {
            display:block;
        }
        p.opened {
            display:none;
        }
    }
    .menu-bg {
        overflow: hidden;
        transition-property: all;
        transition-duration: .4s;
        transition-delay: .1s;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top:0;
        left:0;
        background-color: $colorBG;
        pointer-events: none;
        opacity:0;
    }
    .menu-items {
        z-index: 1;
        display:flex;
        flex-direction: column;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        opacity: 0;
        pointer-events: none;
        ul {
            padding:0;
            margin:0;
            width:100vw;
            li {
                width: 100%;
                text-align: center;
                margin-bottom: 14px;
                margin-top: 14px;
                a {
                    text-decoration: none;
                    &:visited,
                    &:focus,
                    &:active {
                        color: #000;
                        text-decoration: none;
                    }
                    &.active {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    &.opened {
        .menu-icon {
            
            p.closed {
                display:none;
            }
            p.opened {
                display:block;
            }
        }
        .menu-items {
            opacity: 1;
            pointer-events: all;
            ul {
                li {
                    animation-name: fadeInUp;
                    animation-duration: .1s;
                    animation-delay: calc(.4s + .07s * var(--index))
                }
            }
        }
        .menu-bg {
            opacity: 1;
            pointer-events: all;
        }
        &.inverse {
            .menu-icon {
                background-color: #000;
                p {
                    color: #fff;
                }
            }
        }
    }
    &.inverse {
        .menu-icon {
            background-color: #fff;
            p {
                color: #000;
            }
        }
    }
}

@media (max-width: $mobileBreak) {
    .menu {
        margin-right: 20px;
        .menu-icon {
            width: 60px;
            height: 60px;
            p {
                font-size: pt2Rem(12);
                line-height: pt2Rem(17);
                letter-spacing: pt2Rem(.5);
            }
        }
        .menu-items {
            ul {
                li {

                }
            }
        }
    }
}