.animate-hide {
    animation-name: animatehide;
    animation-duration: .3s;
    animation-timing-function: ease-in;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}

@keyframes animatehide {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.animtate-show {
    animation-name: animateshow;
    animation-duration: .3s;
    animation-timing-function: ease-in;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}

@keyframes animateshow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.entrance-fly-from-bottom {
    animation-name: entranceFlyFromBottom;
    animation-duration: .3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes entraceFlyFromBottom {
    from {
        opacity: 0;
        scale: 1.3;
        transform: translateY(30%);
    }
    to {
        opacity: 1;
        scale: 1;
        transform: translateY(0);
    }
}