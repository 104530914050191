@import '../../global/styles/variables';

.works-grid {
    padding-top:200px;
    padding-bottom: 200px;
    display:flex;
    min-height: initial !important;
    flex-direction: row;
    justify-content: center;
    .grid {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .item {
        display:block;
        width: 388px;
        padding:4px;
        transition-property: all;
        transition-duration: .3s;
        .item-inner {
            img {
                display:block;
                width: 100%;
            }
        }
        &:hover {
            filter:brightness(1.8);
        }
    }
}

@media (max-width: $mobileBreak) {
    section.works-grid {
        padding-top: 50px;
        padding-bottom: 70px;
        &>.container {
            width:100%;
            padding-left:0;
            padding-right:0;
            .item {
                width: 100%;
            }
        }
    }
}