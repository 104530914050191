@import '../../global/styles/mixins';
@import '../../global/styles/variables';

.works-so-far {
    padding-top: 155px;
    .page-title {
        margin-top:0;
    }
    h1.sub {
        margin-top:0;
        margin-bottom: 20px;
    }
    .cards-container-wrapper {
        position: relative;
        height: calc(100vh - 310px);
        overflow:hidden;
        width:100%;
    }
    .cards-container {
        padding-bottom:80px;
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left:-18px;
        margin-right:-18px;
        box-sizing: border-box;
        position: absolute;
        .card {
            width: 440px;
        }
    }

    .cards-container-navigator {
        display:none;
    }

    &.active {
        h1 {
            animation-duration: .3s;
            animation-name: fadeInUp;
            animation-delay: .4s;
        }
        .cards-container .card {
        }
        .cards-container .card.appear {
            animation-duration: .2;
            animation-name: fadeIn;
            &:nth-child(1) {
                animation-delay: 0s;
            }
            &:nth-child(2) {
                animation-delay: .07s;
            }
            &:nth-child(3) {
                animation-delay: .14s;
            }
        }
    }
    
}

@media (max-width: $mobileBreak) {
    .works-so-far {
        padding: 0;
        &>.container {
            padding-top: 100px;
            box-sizing: border-box !important;
            justify-content: flex-start;
        }
        h1.sub {
            font-size: pt2Rem(40);
            line-height: pt2Rem(66);
            margin-bottom: 30px;
        }
        .cards-container-wrapper {
            margin-right: -999px;
            position: relative;
            height: calc(100vh - 310px);
        }
        .cards-container {
            position: absolute;
            left: 0;
            padding:0;
            width: auto;
            margin-left: 0;
            margin-right: 0;
            flex-wrap: nowrap;
            transition-property: all;
            transition-duration: .2s;
            .card {
                width: 360px;
                height: 550px;
                margin: 0 7px 0 0;
                justify-content: flex-start;
                .body {
                    white-space: normal;
                    width: 360px;
                    a {
                        display:block;
                        width: 100%;
                        box-sizing: border-box;
                        padding-right:5px;
                    }
                }
                .image {
                    height: 300px;
                    overflow: hidden;
                    position: relative;
                    margin-bottom: 20px;
                    img {
                        position: absolute;
                        width:auto;
                        height: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }

            &.swiping {
                transition-property: none;
            }
        }
        
        .cards-container-navigator {
            display:block;
            margin-bottom: 30px;
        }
    }
}