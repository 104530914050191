@import '../../global/styles/variables';
@import '../../global/styles/mixins';
@import '../../global/styles/typography';

.referee-person {
    color: #000;
    display:flex;
    flex-direction: column;
    position: relative;
    width: 250px;
    height: 147px;
    justify-content: start;
    * {
        text-align: center;
    }
    .initials {
        font-family: $fontFamily;
        font-weight: 900;
        font-size: pt2Rem(141);
        line-height: pt2Rem(66);
        color: #fff;
        
    }
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
            * {
                padding:0;
                margin:0;
            }
            .name {
                margin-bottom: 8px;
            }
            .company {
                font-weight: 500;
            }
            .position {
                font-weight: 500;
            }
        }
        .footer {
            .email {
                @extend p;
                text-decoration: none;
                padding:0;
                margin:0;
                font-weight: 400;
            }
        }
    }
}

@media (max-width: $mobileBreak) {
    .referee-person {
        margin-bottom:40px;
        justify-content: none;
        .initials {
            padding-top:0;
            justify-self: center;
        }
        .overlay {
            height: 120px;
            justify-content: space-around;
        }
    }
}