@import '../../global/styles/_variables.scss';
@import '../../global/styles/_mixins.scss';

.parallex-gallery {
  .full-image {
    width: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .caption-photo {
    width: 100%;
    position: relative;
    .caption {
      position: absolute;
      z-index: 2;
      top: 3em;
      left: 2em;
      color: #fff;
      font-size: 50px;
      font-weight: 900;
      width: 40%;
      font-family: $fontFamily;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .photo-row {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    .item {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display:block; 
      }
    }
  }

  .photos-wrap {
    display: flex;
    flex-direction: row;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display:block;
    }
    &>.item {
      display:flex;
      flex-direction: column;
      .item {
        flex:1 1 auto;
      }
    }
  }

  .video-insert {
    position: relative;
    height: 100vh;
    .video {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      right: 4em;
      width: 50%;
      video {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
