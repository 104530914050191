@import '../../global/styles/variables';

section {
    .container {
        display:flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        .scrollable { 
            width: calc(100% - 162px);
            position: absolute;
            left:0;
            padding-left: 81px;
            padding-right: 81px;
            padding-bottom: 120px;
            padding-top: 180px;
            display: flex;
            flex-direction: column;
            min-height: calc(100vh - 300px);
        }
    }
}

@media (max-width: $mobileBreak) {
    section {
        .container {
            .scrollable { 
                width: calc(100% - 42px);
                padding-bottom: 120px;
                padding-top: 100px;
                padding-left: 21px;
                padding-right: 21px;
                min-height: calc(100vh - 220px);
            }
        }
    }
}