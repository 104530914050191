@import '../../global/styles/variables';

@media (max-width: $mobileBreak) {
    header {
        top: 34px;
        .inner {
            .section {
                padding-left:20px;
                padding-right:20px;
                &>a {
                    margin-top: 30px;
                }
            }
        }
        &.inverse {
            .home {
                color: $colorBGMustard;
            }
            .menu {
                .menu-icon {
                    background-color: $colorBGMustard;
                    color: #fff;
                }
            }
        }
    }
}