.impossible {
    background-color: #fff;
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
    
    .l {
        position: absolute;
        background-color: #666;
    }
    .r {
        height: 5px;
        left: 0;
        width: 100%;
    }
    .c {
        width: 5px;
        top: 0;
        height: 100%;
    }
    .d {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        border:2px solid #fff;
        background-color: #000;
        transform:translate(-2.5px, -2.5px);
    }
    .layer-rotate {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        .r {
            width: 200vw;
            transform-origin: top left;
            transform: rotate(45deg) translateY(-50%);
            
            &.o {
                transform-origin: top left;
                transform: rotate(-45deg) translateY(50%);
            }
        }
        .c {
            transform-origin: top left;
            transform: rotate(-45deg) translateX(-50%);
            
            &.o {
                transform-origin: top left;
                margin-left:132%;
                margin-top:50%;
                transform: rotate(45deg) translateX(50%);
            }
        }
    }
}