.arrow {
    background:transparent;
    border:none;
    pointer-events: all;
    cursor: pointer;
    outline:none;
    &.faded {
        opacity: .3;
        pointer-events: none;
    }

}