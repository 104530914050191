@import '../../global/styles/typography';

header {
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 48px;
    .inner {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        section {
            padding-left:81px;
            padding-right:81px;
            display:flex;
            position: relative;
            .home {
                transition-property: all;
                transition-duration: .3s;
            }
            &>a {
                @extend p;
                color: #000;
                cursor: pointer;
                width: auto;
                float:left;
                text-decoration: none;
                margin-top: 46px;
            }
        }
    }

    &.theatre {
        .home {
            color: #fff;
            opacity: .4;
        }
        .menu {
            opacity: 0;
            pointer-events: none;
        }
    }
    &.inverse {
        .home {
            color: #fff;
        }
    }
}

@media (min-width: 1440px) {
    header {
        .inner {
            width: 1440px;
        }
    }
}

@import './header__mobile.scss';