.loader {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:100px;
    height: 100px;
    z-index: 15;
    transition-property: all;
    transition-delay: .1s;
    transition-duration: .3s;
    &.active {
        opacity: 1;
    }
}