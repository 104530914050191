@import '../../global/styles/variables';
@import '../../global/styles/mixins';
@import '../../../node_modules/animate.css/animate.css';

section.work-video-player {
    height: 100vh !important;
    position: relative;
    overflow: hidden;
    .captions {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: inherit;
        z-index: 3;
        display:flex;
        flex-direction: column;
        justify-content: center;
        img {
            display:block;
            margin-left:auto;
            margin-right:auto;
        }
        p.video-title {
            font-size: pt2Rem(54);
            text-align: center;
            color: #fff;
        }
        button.btn-play-video {
            font-family: $fontFamily;
            font-weight: 600;
            line-height: pt2Rem(50);
            font-size: pt2Rem(40);
            letter-spacing: pt2Rem(-0.4);
            outline:none;
            border:none;
            width:auto;
            padding:0;
            border-bottom: 1px solid #fff;;
            background-color: transparent;
            color: #fff;
            align-self: center;
            cursor: pointer;
        }
    }
    .img-container {
        margin-left:-81px;
        margin-right:-81px;
        overflow: hidden;
        height: inherit;
        transition-property: all;
        transition-duration: .3s;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        img {
            position: absolute;
            object-fit: cover;
            object-position: center;
            display: block;
            width: 100%;
            height:100%;
            z-index: 1;
            filter:brightness(.5);
        }
        video {
            filter:brightness(.5);
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            z-index: 2;
            transition-property: all;
            transition-duration: .4s;
            &.visible {
                opacity: 1;
            }
        }
        &.dim {
            filter:brightness(.5);
        }
    }
    .video {
        position: fixed;
        height: inherit;
        display: none;
        z-index: 4;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 100vw;
        transition-property: all;
        transition-delay: .3s;
        transition-duration: .3s;
        opacity: 0;
        background-color:#000;
        video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display:block;
            width: 100%;
        }
        .video-control {
            position: fixed;
            z-index: 999;
            bottom: 50px;
            left: 50%;
            margin-left: -10px;
            opacity: 0;
            pointer-events: none;
            button.btn-pause-play {
                cursor: pointer;
                outline:none;
                border:none;
                background-color:transparent;
                padding:0;
                margin:0;
                display:flex;
                transition-property: all;
                transition-duration: .3s;
                transform-style: preserve-3d;
                transform: rotateX(0deg);
                transform-origin: top center 0;
                display:flex;
                flex-direction: column;
                .icon-pause {
                    width: 20px;
                    height: 20px;
                    display:flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    &:before,
                    &:after {
                        content: '';
                        height: 100%;
                        width: 5px;
                        background-color: #fff;
                        display:block;
                    }
                }
                .icon-play {
                    width: 20px;
                    height: 20px;
                    transform: rotateX(90deg);
                    transform-origin: bottom center 0;
                    position: relative;
                    left:3px;
                    &:before {
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 10px 0 10px 17px;
                        border-color: transparent transparent transparent #fff;
                        display:block;
                    }
                }
            }
            &.paused {
                button.btn-pause-play  {
                    transform:rotateX(90deg);
                }
            }
        }
        .btn-close-video {
            opacity: 0;
            pointer-events: none;
            position: fixed;
            z-index: 999;
            top: 50px;
            right: 50px;
            width: 40px;
            height: 40px;
            outline:none;
            border:none;
            background-color: transparent;
            &:before {
                position: absolute;
                content: '';
                width: 100%;
                transform:rotate(-45deg);
                height:2px;
                transform-origin: center center;
                background-color: #fff;
            }
            &:after {
                position: absolute;
                content: '';
                width: 100%;
                transform:rotate(45deg);
                height:2px;
                transform-origin: center center;
                background-color: #fff;
            }
        }
        &.active {
            display: block;
            opacity: 1;
        }
    }

    &.active {
        .captions {
            img {
                animation-delay:.6s;
                animation-duration: .3s;
                animation-name: zoomIn;
            }
            p.video-title {
                animation-delay:.8s;
                animation-duration: .3s;
                animation-name: zoomIn;
            }
            button.btn-play-video {
                animation-delay:1s;
                animation-duration: .3s;
                animation-name: zoomIn;
            }
        }
        .img-container {
            animation-delay:.4s;
            animation-duration: .4s;
            animation-name: fadeIn;
        }
    }

    &.video-loading {
        .captions {
            opacity: 0;
            pointer-events: none;
        }
    }

    &.video-started {
        .video {
            .video-control {
                opacity:1;
                pointer-events: all;
            }
            .btn-close-video {
                cursor: pointer;
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

@media (max-width: $mobileBreak) {
    .work-video-player {
        height:auto;
        .captions {
            width: 297px;
            img {
                max-width: 266px;
                max-height: 115px;
            }
            p.video-title {
                font-size: pt2Rem(50);
            }
            button.btn-play-video {
                font-size: pt2Rem(30);
                letter-spacing: pt2Rem(-0.3);
            }
        }
        .img-container {
            height: 100vh;
            @media (orientation: portrait) {
                img {
                    height: 100% !important;
                    width:auto !important;
                }
            }
            @media (orientation: landscape) {
                img {
                    height: auto !important;
                    width: 100% !important;
                }
            }
        }
        .video {
            video {
                height: 100%;
            }
            @media (orientation: portrait) {
                video {
                    height: 100% !important;
                    width:auto !important;
                }
            }
            @media (orientation: landscape) {
                video {
                    height: auto !important;
                    width: 100% !important;
                }
            }
        }
    }
}