@import '../../global/styles/variables';

.bio {
    .container {
        height:auto !important;
        min-height: unset !important;
        max-width: 1440px;
    }
    .page-title {
        text-align: center;
        margin-bottom: 120px;
        margin-top: 155px;
    }
    .page {
        height:auto;
    }
    section.autoheight {
        .container {
            height:auto;
            min-height: unset;
        }
    }
    section.download {
        height:auto;
        min-height: unset;
        a {
            width: 300px;
            text-decoration: none;
            padding: 15px 30px;
            border:2px solid #000;
            text-align: center;
            display:block;
            margin-left:auto;
            margin-right:auto;
        }
    }
    .back-to-top {
        position: relative;
        top:unset;
        margin-bottom: 50px;
    }
}

@media (max-width: $mobileBreak) {
    .bio {
        .page-title {
            margin-top:170px;
        }
    }
}