@import './variables';

html {
    font-size: 16px;
}

@for $i from 1 through 5 {    
    .mt-#{$i} {
        margin-top: $i * 1rem;
    }
    .mb-#{$i} {
        margin-bottom: $i * 1rem;
    }
}

.hide {
    opacity: 0;
    pointer-events: none;
}

section.autoheight {
    min-height: initial !important;
    height:auto;
}

.bg-white {
    background-color: $colorFG2;
}
.bg-mustard {
    background-color: $colorBGMustard;
}
.bg-black {
    background-color: #000;
    * {
        color: #fff !important;
    }
}
.fg-white {
    color: #fff !important;
}

.float-bt-center {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index:5;
    transition-property: all;
    transition-duration: .3s;
}

.bold {
    font-weight: 700;
}

@media (max-width: $mobileBreak) {
    .float-bt-center {
        /* display:none; */
    }
}