@import '../../global/styles/typography';
@import '../../global/styles/variables';

.password-protect {
    width: 100vw;
    height: 100vh;
    background-color: $colorBG;
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation-name: fadeIn;
    animation-duration: .3s;
    animation-delay: 0s;

    img {
        max-width: 1440px;
        width: 100%;
        display:block;
        align-self: center;
        margin-bottom: 50px;

        &.valid {
            animation-name: fadeOut;
            animation-duration: .3s;
            animation-delay: 0s; 
        }
    }

    input {
        animation-name: fadeIn;
        animation-duration: .3s;
        animation-delay: .3s;
        text-align: center;
        width: 430px;
        letter-spacing: 4px;    
        outline: none;
        padding: 0px 10px;
        height: 60px;
        @extend .regular-headline;
        background-color:#000;
        border:2px solid #000;
        color: $colorBGMustard;
        align-self: center;
        background-image:none !important;
        transition-property: all;
        transition-duration: .3s;

        &:-internal-autofill-selected {
            background-color:#000;
            color: $colorBGMustard;
        }

        &::placeholder {
            color: $colorBGMustard;
        }

        &:focus {
            background-color: $colorBG;
            color: #000;
        }

        &.ready {
            opacity: 1;
            animation-name: none;
        }

        &.not-valid {
            animation-name: headShake;
            animation-duration: .5s;
            animation-delay: 0s; 
        }

        &.valid {
            animation-name: backOutUp;
            animation-duration: .5s;
            animation-delay: 0s; 
        }
    }
}

@media (max-width: $mobileBreak) {
    .password-protect {
        img {
            max-width: unset;
            width: 80%;
        }
        input {
            width: 80%;
        }
    }
}