@import '../../global/styles/mixins';
@import '../../global/styles/variables';

.contact {
    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .item {
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            img {
                display:block;
                margin-bottom:15px;
            }
            .sub {
                font-size: pt2Rem(16);
                font-weight: 700;
            }
            p.sub {
                margin:0;
                padding:0;
            }
            a.sub {
                margin:0;
                padding:0;
                text-decoration: none;
            }
        }
    }
}

@media (max-width: $mobileBreak) {
    .contact {
        align-items: center;
        h1 {
            text-align: center;
            padding-left:50px;
            padding-right:50px;
        }
        .row {
            justify-content: start;
            flex-wrap: wrap;
            .item {
                width: 50%;
                margin-top:20px;
                margin-bottom: 20px;
            }
        }
    }
}