@import '../../global/styles/typography';
@import '../../global//styles/variables';

.works-detail {
    transition-property: all;
    transition-duration: .3s;
    display:flex;
    flex-direction: column;

    &.loading {
        opacity: 0;
    }
    .container {
        min-height: unset !important;
    }
    .banner-container {
        width:auto;
        height: 100vh;
        position: relative;
        background-color: #000;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        .caption {
            z-index: 2;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            animation-name: fadeIn;
            animation-delay: .5s;
            animation-duration: .3s;
            * {
                color: #fff;
            }
            .logo {
                
            }
            .client {
                font-family: 'Akhand-Black';
                font-size: 90px;
                text-transform: uppercase;
                text-align: center;
            }
            .project {
                font-family: 'Akhand-Black';
                font-size: 70px;
                text-transform: uppercase;
                text-align: center;
            }
            .eye-brow {
                @extend p.sub;
                font-size:18px;
                margin-top:20px;
                text-align: center;
                ;line-height: 24px;
            }
        }
        img.banner {
            display:block;
            width: 100%;
            opacity: 0;
            animation-duration: .3s;
            animation-delay: .2s;
            animation-name: fadeIn;
        }
    }
    h1.page-title {
        padding:0;
        margin-top: 100px;
        margin-bottom: 50px;
        width: 50%;
        &.inview {
            animation-name: fadeInUp;
        }
    }
    .body-quote {
        padding:0;
        margin-top: 100px;
        margin-bottom: 50px;
        display:flex;
        flex-direction: row;
        justify-content: space-evenly;
        .body-copy {
            padding-right: 70px;
            &.inview {
                animation-name: fadeInLeft;
            }
            a {
                @extend .body-copy;
            }
        }
        blockquote {
            &.inview {
                animation-name: fadeInRight;
            }
            &:before {
                content: '“'
            }
            &:after {
                content: "”"
            }
        }
    }
    hr {
        border:none;
        height:1px;
        background-color: rgba(255,255,255, .4);
        margin-left:-81px;
        margin-right:-81px;
        &.mb-5 {
            margin-bottom: 100px;
        }
    }
    .results {
        margin-top:100px;
        margin-bottom: 100px;
        .regular-headline {
            width: 50%;
            &.full-width {
                width: 100%;
            }
            &.inview {
                animation-name: fadeInUp;
            }
        }
        .statements {
            margin-top:80px;
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: -20px;
            margin-right: -20px;
            .sub {
                &.inview {
                    animation-name: fadeInRight;
                    animation-delay: calc(var(--index) * .1s);
                }
                box-sizing: border-box;
                flex-grow: 1;
                width:200px;
                font-weight: 500;
                padding-left: 20px;
                padding-right: 20px;
                border-right: 1px solid rgba(255,255,255, .4); 
                &:nth-child(4n) {
                    border-right: none;
                }
                a {
                    @extend .sub;
                    border-right: none;
                }
            }
        }
    }
    .back-to-top {
        position: relative;
        top:auto;
        bottom: 50px;
    }

    .inview-track {
        opacity: 0;
        animation-duration: .3s;
        animation-delay: .2s;
    }
    section.gallery {
        display:flex;
        flex-direction: column;
        min-height: initial !important;
        .item {
            float:left;
            img {
                display: block;
                width: 100%;
                float:left;
                &.inview {
                    animation-name: fadeInUp;
                }
            }
            .row {
                display:flex;
                float:left;
                width: 100%;
                align-items: stretch;
                justify-content:center;
                div {
                    flex-grow: 1;
                    padding:15px;
                    img {
                        width:100%;
                        display:block;
                        &.inview {
                            animation-name: fadeInUp;
                        }
                    }
                    
                    &:first-child {
                        padding-left:30px;    
                    }
                    &:last-child {
                        padding-right:30px;
                    }
                }
            }
            .video {
                height: 100vh;
            }
            .regular-headline {
                margin-top: 60px;
                margin-bottom: 60px;
                &.inview {
                    animation-name: fadeInUp;
                }
            }
            &>hr {
                border:none;
                height:1px;
                background-color: rgba(255,255,255, .4);
                margin:0;
            }
        }
    }
}



@media (max-width: $mobileBreak) {
    .works-detail {
        .banner-container {
            .caption {
            }
            img.banner {
                height: 100vh;
                object-position: center;
                object-fit: cover;
            }
        }
        h1.page-title {
            width: 100%;
        }
        .body-quote {
            flex-direction: column;
            .body-copy {
                padding-right: 0;
            }
            blockquote {
                text-align: center;
            }
        }
        hr {
            border:none;
            height:1px;
            background-color: rgba(255,255,255, .4);
            margin-left:-21px;
            margin-right:-21px;
        }
        .results {
            .regular-headline {
                width: 100%;
            }
            .statements {
                flex-direction: column;
                .sub {
                    border-right: none;
                    width:90%;
                }
            }
        }
        section.gallery {
            margin:0;
            .item {
                .row {
                    display:flex;
                    float:left;
                    flex-direction: column;
                    width: 100%;
                    align-items: stretch;
                    justify-content:center;
                    div {
                        flex-grow: 1;
                        img {
                            width:100%;
                            display:block;
                            &.inview {
                                animation-name: fadeInUp;
                            }
                        }
                    }
                }
                .video {
                    height: auto;
                    width:100%;
                }
            }
        }
    }
}