.card {
    width: 404px;
    margin-left: 17px;
    margin-right: 17px;
    display:flex;
    flex-direction: column;
    margin-bottom: 75px;
    justify-content: space-between;
    .body {
        a {
            text-decoration: none !important;
        }
        .image {
            width: 100%;
            overflow: hidden;
            margin-bottom: 8px;;
            img {
                display:block;
                width: 100%;
            }
        }
        h4 {
            margin-left:2px;
            margin-right:2px;
            width: 100%;
            margin:0;
            padding:0;
            margin-bottom: 4px;
            min-height: 30px;
        }
        p.sub {
            padding-top: 0;
            margin-top: 0;
            white-space: normal;
            margin-left:2px;
            margin-right:2px;
        }
    }
    .footer {
        a.sub {
            align-self: flex-end;
        }
    }
}