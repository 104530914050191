@import './global/styles/variables';
@import './global/styles/typography';
@import './global/styles/layout';
@import './global/styles/animations';

body {
    padding: 0;
    margin: 0;
    background: #000;
    overscroll-behavior: none;
}