@import '../../global//styles/variables';

.video-player {
    width: inherit;
    height: inherit;
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
    .loader-container {
        position: absolute;
        z-index: 1;
    }
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .controls {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition-property: all;
        transition-duration: .3s;
        pointer-events: none;
        background-color: rgba(0,0,0, .6);
        padding:18px;
        border-radius: 9px;
        height: 20px;
        overflow: hidden;
        button.btn-pause-play {
            cursor: pointer;
            outline:none;
            border:none;
            background-color:transparent;
            padding:0;
            margin:0;
            display:flex;
            transition-property: all;
            transition-duration: .3s;
            transform-style: preserve-3d;
            transform: rotateX(0deg);
            transform-origin: top center 0;
            display:flex;
            flex-direction: column;
            .icon-pause {
                width: 20px;
                height: 20px;
                display:flex;
                flex-direction: row;
                justify-content: space-evenly;
                &:before,
                &:after {
                    content: '';
                    height: 100%;
                    width: 5px;
                    background-color: #fff;
                    display:block;
                }
            }
            .icon-play {
                width: 20px;
                height: 20px;
                transform: rotateX(90deg);
                transform-origin: bottom center 0;
                position: relative;
                left:3px;
                &:before {
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 10px 0 10px 17px;
                    border-color: transparent transparent transparent #fff;
                    display:block;
                }
            }
        }
    }
    &.ready {
        .controls {
            opacity: 1;
            pointer-events: all;
            button.btn-pause-play {
                transform: rotateX(90deg);
            }
        }
        &.playing {
            .controls {
                button.btn-pause-play {
                    transform: rotateX(0deg);
                }
            }
        }
        &.started {
            .controls {
                top: 100%;
                transform: translate(-50%, -125%);
            }
        }
    }
    
}

@media (max-width: $mobileBreak) {
    .video-player {
        display:flex;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        overflow: hidden;
        transition-property: all;
        transition-duration: .3s;
        video {
            width:100%;
            height:unset;
        }
        &.ready {
            &.started {
                .controls {
                    top: 100%;
                    transform: translate(-50%, -120%);
                }
            }
        }
        &.playing {
            video {
                object-fit: contain;
                height: 100vh;
            }
        }
    }
}