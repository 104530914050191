@import '../../global/styles/mixins';
@import '../../global/styles/variables';

.home {
    .name-profile-picture {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background-image:url('/img/profile-pic.jpg');
            background-size: cover;
            left: 50%;
            bottom: 45px;
            transform: translateX(-50%);
            opacity:0;
            transition-duration: .3s;
            transition-property: all;
        }
        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
}
.section-text>.container {
    h1 {
        padding:0;
        padding-bottom: 10px;
        padding-top:30px;
        margin:0;
        font-size: pt2Rem(36);
        line-height: pt2Rem(46);
    }
    .row {
        display:flex;
        margin-left:-40px;
        margin-right:-40px;
        .col {
            flex-grow: 1;
            width: 50%;
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}

.the-story-so-far>.container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    h1 {
        margin-bottom: 0;
    }
    h3 {
        margin-top: 0;
    }
    &.active {
        h1 {
            animation-name: fadeInUp;
            animation-delay:.4s;
            animation-duration: .4s;
        }
        h3 {
            animation-name: fadeInUp;
            animation-delay:.6s;
            animation-duration: .4s;
        }
    }
}

section.referees>.container, 
section.contact>.container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    &.active {
        h1 {
            animation-name: fadeInUp;
            animation-delay:.4s;
            animation-duration: .4s;
        }
    }
}

section.contact {
    .contact-inner {
        display:flex;
        flex-direction: column;
        width:100%;
        position: relative; 
        height: 100%;
        flex-grow: 1;
        .back-to-top-row {
            position: absolute;
            right:0;
            bottom: -120px;
            display:flex;
            flex-direction: row;
            justify-content: flex-end;
        }
    }
}

section.referees.active>.container {
    .referee-person {
        animation-name: fadeInRight;
        animation-delay: calc(.4s + var(--index) * .2s);
        animation-duration: .3s;
    }
}

section.contact.active>.container {
    .item {
        animation-name: fadeInRight;
        animation-delay: calc(.4s + var(--index) * .2s);
        animation-duration: .3s;
    }
}
section.text-1.active>.container {
    overflow: hidden;
    h1 {
        animation-name: fadeInUp;
        animation-delay:.4s;
        animation-duration: .4s;
    }
    p.sub {
        animation-name: fadeInUp;
        animation-delay:.6s;
        animation-duration: .4s;
    }
}
section.text-1.active.active-from-bottom>.container {
    overflow: hidden;
    h1 {
        animation-name: fadeInDown;
        animation-delay:.4s;
        animation-duration: .4s;
    }
    p.sub {
        animation-name: fadeInDown;
        animation-delay:.6s;
        animation-duration: .4s;
    }
}

section.text-2.active>.container {
    overflow: hidden;
    h4 {
        animation-name: fadeInUp;
        animation-delay:.4s;
        animation-duration: .4s;
    }
    .col {
        p {
            animation-name: fadeInUp;
            animation-duration: .4s;
        }
        p:nth-child(1) {
            animation-delay:.6s;
        }
        p:nth-child(2) {
            animation-delay:.8s;
        }
        p:nth-child(3) {
            animation-delay:1.2s;
        }
    }
}

section.text-2.active.active-from-bottom>.container {
    overflow: hidden;
    h4 {
        animation-name: fadeInDown;
        animation-delay:.4s;
        animation-duration: .4s;
    }
    .col {
        p {
            animation-name: fadeInDown;
            animation-duration: .4s;
        }
        p:nth-child(1) {
            animation-delay:.6s;
        }
        p:nth-child(2) {
            animation-delay:.8s;
        }
        p:nth-child(3) {
            animation-delay:1.2s;
        }
    }
}

section.active>.container {
    .back-to-top {
        animation-delay:.4s;
        animation-name: fadeInDown;
        animation-duration: .4s;
    }
}

@media (min-width: 1441px) {
    section.text-1>.container {
        h1 {
            font-size: pt2Rem(50);
            line-height: pt2Rem(66);
        }
    }
    section.text-2>.container {
        h4 {
            font-size: pt2Rem(40);
            line-height: pt2Rem(50);
        }
        .col {
            p {
                font-size: pt2Rem(28);
                line-height: pt2Rem(40);
            }
        }
    }
}

@import './home__mobile.scss';