@import './global/styles/variables';
@import '../node_modules/animate.css/animate.css';

.App {
    .page {
        width: 100%;
        height: 100vh;
        margin-left: auto;
        margin-right: auto;
        transition-timing-function: ease-in-out;
        transition-property: all;
        transition-duration: .5s;
        overflow: hidden;
        &.auto-height {
            overflow: visible;
            height:auto;
        }
    }
    .page>section {
        min-height: 100vh;
        position: relative;
        box-sizing: content-box;
        .container {
            padding-left: 81px;
            padding-right: 81px;
            min-height: 100vh;
            margin-left:auto;
            margin-right:auto;
            position: relative;
            box-sizing: content-box;
        }
    }
}

@media (max-width: $mobileBreak) {
    .App {
        .page {
            /* overflow-y: visible !important;
            height: auto; */
            section {
                /* height:auto;
                min-height: unset; */
                .container {
                    padding-left: 22px;
                    padding-right: 22px;
                }
            }
        }
    }
}

@media (min-width: 1440px) {
    .App {
        .page {
            section {
                &>.container {
                    max-width: 1440px;
                }
            }
        }
    }
}

