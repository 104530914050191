@import '../../global/styles/variables';

.back-to-top {
    display:flex;
    flex-direction: column;
    text-decoration: none;
    padding-bottom: 30px;
    cursor: pointer;
    img {
        display:block;
        align-self: flex-end;
        width:25px;
    }
    p {
        padding:5px 0px;
        margin:0;
    }
    &.inverse {
        * {
            color: #fff;
        }
    }
}

@media (max-width: $mobileBreak) {
    .back-to-top {
        padding-bottom: 30px;
    }
}