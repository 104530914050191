@import '../../global/styles/variables';

.referees {
    width: 100%;
    .row {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (max-width: $mobileBreak) {
    .referees {
        &>.container {
            .scrollable {
                padding-bottom: 0;
            }
        }
        h1 {
            font-weight: 700 !important;
            padding-left:20px;
            padding-right:20px;
            margin-bottom: 40px;;   
        }
        .row {
            flex-direction: column;
            align-items: center;
        }
    }
}