@import '_variables.scss';
@import '_mixins.scss';


h1.page-title {
    font-family: $fontFamily;
    font-weight: 900;
    font-size: pt2Rem(54);
    letter-spacing: 0;
    line-height: pt2Rem(66);
    color: $colorFontFG;
}

h1, .large-black-headline {
    font-family: $fontFamily;
    font-weight: 900;
    font-size: pt2Rem(54);
    letter-spacing: 0;
    line-height: pt2Rem(66);
    color: $colorFontFG;
}

h1.sub {
    font-family: $fontFamily;
    font-weight: 700;
    font-size: pt2Rem(54);
    letter-spacing: 0;
    line-height: pt2Rem(66);
    color: $colorFontFG;
}

h2, .medium-black-headline {
    font-family: $fontFamily;
    font-weight: 900;
    font-size: pt2Rem(40);
    letter-spacing: pt2Rem(-0.4);
    line-height: pt2Rem(50);
    color: $colorFontFG;
}

h3, .regular-headline {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: pt2Rem(40);
    letter-spacing: pt2Rem(-0.4);
    line-height: pt2Rem(50);
    color: $colorFontFG;
}

h4, .sub-headline {
    font-family: $fontFamily;
    font-weight: 700;
    font-size: pt2Rem(24);
    letter-spacing: 0;
    line-height: pt2Rem(32);
    color: $colorFontFG;
}

p, .body-copy {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: pt2Rem(24);
    letter-spacing: 0;
    line-height: pt2Rem(32);
    color: $colorFontFG;
    a {
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        line-height: inherit;
        word-break: break-all;
    }
}

a {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: pt2Rem(40);
    letter-spacing: 0;
    line-height: pt2Rem(56);
    color: $colorFontFG;
}

a.sub {
    font-family: $fontFamily;
    font-weight: 700;
    font-size: pt2Rem(12);
    letter-spacing: pt2Rem(1.5);
    line-height: pt2Rem(20);
    color: $colorFontFG;
}

p.sub {
    font-family: $fontFamily;
    font-weight: 400;
    font-size: pt2Rem(16);
    letter-spacing: 0;
    line-height: pt2Rem(24);
    color: $colorFontFG;
    a {
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        line-height: inherit;
        word-break: break-all;
    }
}

.video-title {
    font-family: $fontFamily;
    font-weight: 200;
    line-height: pt2Rem(46);
    font-size: pt2Rem(80);
    letter-spacing: 0;
    color: #fff;
}

@media (max-width: $mobileBreak) {
    
    h1.page-title {
        font-size: pt2Rem(40);
        letter-spacing: 0;
        line-height: pt2Rem(46);
    }

    h1, .large-black-headline {
        font-size: pt2Rem(30);
        letter-spacing: 0;
        line-height: pt2Rem(40);
    }
    
    h1.sub {
        font-family: $fontFamily;
        font-weight: 700;
        font-size: pt2Rem(54);
        letter-spacing: 0;
        line-height: pt2Rem(66);
        color: $colorFontFG;
    }
    
    h2, .medium-black-headline {
        font-family: $fontFamily;
        font-weight: 900;
        font-size: pt2Rem(40);
        letter-spacing: pt2Rem(-0.4);
        line-height: pt2Rem(50);
        color: $colorFontFG;
    }
    
    h3, .regular-headline {
        font-family: $fontFamily;
        font-weight: 400;
        font-size: pt2Rem(28);
        letter-spacing: pt2Rem(-0.4);
        line-height: pt2Rem(34);
        color: $colorFontFG;
    }
    
    h4, .sub-headline {
        font-family: $fontFamily;
        font-weight: 700;
        font-size: pt2Rem(24);
        letter-spacing: 0;
        line-height: pt2Rem(30);
        color: $colorFontFG;
    }
    
    a {
        font-family: $fontFamily;
        font-weight: 400;
        font-size: pt2Rem(40);
        letter-spacing: 0;
        line-height: pt2Rem(56);
        color: $colorFontFG;
    }
    
    a.sub {
        font-family: $fontFamily;
        font-weight: 700;
        font-size: pt2Rem(12);
        letter-spacing: pt2Rem(1.5);
        line-height: pt2Rem(20);
        color: $colorFontFG;
    }
    
    p, body-copy {
        font-family: $fontFamily;
        font-weight: 400;
        font-size: pt2Rem(16);
        letter-spacing: 0;
        line-height: pt2Rem(24);
        color: $colorFontFG;
    }
    
    p.sub {
        font-size: pt2Rem(12);
        letter-spacing: 0;
        line-height: pt2Rem(16);
    }
    
    .video-title {
        font-family: $fontFamily;
        font-weight: 200;
        line-height: pt2Rem(46);
        font-size: pt2Rem(80);
        letter-spacing: 0;
        color: #fff;
    }
}