@import '../../global/styles/mixins';

@media (max-width: $mobileBreak) {
    .section-text>.container {
        h1 {
            font-size: pt2Rem(30);
            line-height: pt2Rem(40);
        }
    }
    section.text-2>.container {
        .row {
            flex-direction: column;
            margin-left: 0px;
            margin-right: 0px;
            padding-top: 50px;
            .col {
                width: 100%;
                padding-left:0;
                padding-right:0;
                h4 {
                    margin-bottom: 5px;;
                }
                p {     
                    font-size: pt2Rem(16);
                    line-height: pt2Rem(24);
                }
            }
        }
    }
    .the-story-so-far>.container {
        h1 {
            margin-bottom: 24px;
        }
        h3,a {
            font-size: pt2Rem(28);
            line-height: pt2Rem(34);
        }
    }
    
    section.referees>.container {
        h1 {
            text-align: center;
        }
    } 
    section.contact>.container {
        display:flex;
        flex-direction: column;
        justify-content: center;
        
        .back-to-top-row {
            right: 20px;
        }
        &.active {
            h1 {
                animation-name: fadeInUp;
                animation-delay:.4s;
                animation-duration: .4s;
            }
        }
    }
    
}