@import '../../global/styles/typography';

.bio-highlight {
    margin-bottom: 95px;
    margin-left: -24px;
    margin-right: -24px;
    .banner {
        display:block;
        width:100%;
        margin-bottom: 70px;
    }
    .banner-with-text {
        margin-top: 160px;
        margin-bottom: 70px;;
        display:flex;
        width:100%;
        flex-direction: row;
        justify-content: space-between;
        img {
            width: 50%;
        }
        h3 {
            text-align:left;
            padding-left:80px;
        }
    }
    .logo {
        display:block;
        margin-left:auto;
        margin-right:auto;
    }
    .company-name {
        text-align: center;
        margin-bottom: 80px;
    }
    .position {
        text-align: center;
        margin-bottom: 80px;
    }
    .period {
        text-align: center;
        margin-bottom: 80px;
    }
    .description {
        @extend p;
        max-width:1440px;
        text-align: center;
        margin-left:auto;
        margin-right:auto;
        margin-bottom: 130px;;
    }
    blockquote {
        @extend h3;
        text-align: center;
        padding-bottom: 0;
        margin-bottom: 0;
        &:before {
            content: '“';
        }
        &:after {
            content: "”";
        }
    }
    p.quote-by {
        text-align: center;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 160px;
        &:before {
            content: "-";
        }
    }
    .banner.current-banner {
        margin-bottom: 160px;
    }
    .quote {
        margin-bottom: 40px;;
    }
    .achievements {
        margin-top:80px;
        .achievement {
            h4 {
                justify-self: left;
                width: 100%;
                margin-left:0;
                margin-right:0
            }
            .items {
                display:flex;
                flex-direction: row;
                flex-wrap: wrap;
                .item {
                    min-height: 230px;
                    margin-bottom: 30px;
                    padding-left: 30px;
                    padding-right: 30px;;
                    box-sizing: border-box;
                    border-right: 1px solid #d8d8d8;
                    p.title {
                        font-weight: 700;
                    }
                    p.sub {
                        margin:0;
                        padding:0;
                    }
    
                    &:last-child {
                        border-right: 1px solid transparent;
                    }
                }
            }
            
            
            &.col-3 {
                .item {
                    width: 33.333%;
                    &:nth-child(3n+3) {
                        border-right: 1px solid transparent;
                    }
                }
            }

            &.col-4 {
                .item {
                    width: 25%;
                    &:nth-child(4n+4) {
                        border-right: 1px solid transparent;
                    }
                }
            }
        }
    }
    .brands {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 880px;
        margin-left:auto;
        margin-right: auto;
        padding-top: 30px;
        img {
            height: 45px;
            display:block;
            align-self: center;
            margin: 21px 24px;
        }
    }
    img.brands {
        display:block;
        margin-left:auto;
        margin-right: auto;
    }
}


@media (max-width: $mobileBreak) {
    .bio-highlight {
        margin-left:0;
        margin-right:0;
        .company-name {
            margin-bottom: 40px;
        }
        .position {
            margin-bottom: 30px;
        }
        .period {
            margin-bottom: 30px;
        }
        .description {
            margin-bottom: 40px;
        }
        p.quote-by, 
        .quote-by.description {
            margin-bottom: 40px;
        }
        .banner,
        .banner.current-banner {
            margin-bottom: 80px;
            margin-top:30px;
        }
        .achievements {
            .achievement {
                h4 {
                    width:auto;
                    text-align: center;
                }
                .items {
                    .item {
                        width: 100% !important;
                        border-right:none;
                        height:auto;
                        min-height:initial;
                        border-right: none;
                    }
                }
            }
        }
        .brands {
            display:block;
            width: 100%;
        }
        .logo {
            display:block;
            width: auto;
            max-width: 375px;
            max-height: 70px;
        }
        .banner-with-text {
            flex-direction: column;
            img {
                width:100%;
            }
            h3 {
                margin:30px 10px;
                padding:0;
                text-align: center;
            }
        }
    }
}


@media (max-width: 1023px) {
    .bio-highlight {
        .achievements {
            .achievement {
                .item {
                    width: 50% !important;
                }
            }
        }
    }
}